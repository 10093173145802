var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('module-navigation-bar',{attrs:{"title":"Assembly Hall Dashboard"},scopedSlots:_vm._u([{key:"module-nav-logo",fn:function(){return [(_vm.isDaiwaLicense)?_c('img',{attrs:{"alt":"","height":"40px","src":"https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"}}):_vm._e()]},proxy:true}])}),(_vm.status === 'success')?_c('div',{staticClass:"d-flex flex-1 overflow-hidden"},[_c('div',{staticClass:"px-5 py-2 background-white ant-border-right",staticStyle:{"max-width":"270px"}},[_c('ant-input',{staticStyle:{"margin-top":"0 !important"},attrs:{"label":"Hall"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-select',{attrs:{"items":_vm.halls,"dense":"","filled":"","hide-details":"","item-text":"title","item-value":"id","placeholder":"Hall","return-object":""},on:{"input":_vm.setHall},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.title)+" "),_c('v-spacer'),_c('v-chip',{staticClass:"mr-2",attrs:{"small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.automated ? 'mdi-robot-industrial' : 'mdi-account-wrench')+" ")]),_vm._v(" "+_vm._s(item.automated ? 'Automated' : 'Manual')+" ")],1)]}}],null,false,261276404),model:{value:(_vm.selectedHall),callback:function ($$v) {_vm.selectedHall=$$v},expression:"selectedHall"}})]},proxy:true}],null,false,1465313514)}),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.assemblyHallNavigation),callback:function ($$v) {_vm.assemblyHallNavigation=$$v},expression:"assemblyHallNavigation"}},[_c('v-list-item',{attrs:{"disabled":!_vm.selectedHall,"link":""},on:{"click":function($event){_vm.$router.push({
                name: `${_vm.DHME_M_ASSEMBLY_HALL_DASHBOARD()}-planning`,
                params: {
                  hall: _vm.selectedHall.id,
                },
              })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar-multiselect")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Planning (modules)")])],1)],1),_c('v-list-item',{attrs:{"disabled":!_vm.selectedHall,"link":""},on:{"click":function($event){_vm.$router.push({
                name: `${_vm.DHME_M_ASSEMBLY_HALL_DASHBOARD()}-trestles`,
                params: {
                  hall: _vm.selectedHall.id,
                },
              })}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mdi-rotate-270"},[_vm._v("mdi-format-list-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Trestles")])],1)],1),(_vm.isSuperAdmin)?_c('v-list-item',{attrs:{"disabled":!_vm.selectedHall,"link":""},on:{"click":function($event){_vm.$router.push({
                name: `${_vm.DHME_M_ASSEMBLY_HALL_DASHBOARD()}-mes`,
                params: {
                  hall: _vm.selectedHall.id,
                },
              })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-server")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("MES")])],1)],1):_vm._e()],1)],1)],1),(_vm.selectedHall)?_c('router-view',{staticClass:"flex-1"}):_vm._e()],1):_c('div',{staticClass:"d-flex justify-center align-center full-height"},[_c('ant-loading')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }