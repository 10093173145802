<script>
import { executeCustomModuleCall } from '@/services/api/module.api';
import { mapGetters } from 'vuex';
import { DHME_M_ASSEMBLY_HALL_DASHBOARD } from '@/modules/modules';
import moment from 'moment/moment';

export default {
  name: 'MES',
  data: () => {
    return {
      mesTacts: [],
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      focusedHall: 'dhmeAssemblyHallDashboardStore/focusedHall',
      tacts: 'dhmeAssemblyHallDashboardStore/tacts',
    }),
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_ASSEMBLY_HALL_DASHBOARD
      ).id;
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch(
        'dhmeAssemblyHallDashboardStore/fetchHallTacts'
      );
      this.mesTacts = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'fetchMesTacts',
        {
          amount: 100,
        }
      );
    } catch (error) {
      this.$store.commit('showNotification', {
        content: 'Could not fetch MES tacts',
        color: 'error',
      });
    }
  },
  methods: {
    loggedInAnt(item) {
      return (
        this.tacts.findIndex((t) => t.mes_id === item.tactId.toString()) !== -1
      );
    },
    getDateTimeFromTimestamp(timestamp) {
      return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
    },
    async addTactToAnt(item) {
      try {
        await this.$store.dispatch(
          'dhmeAssemblyHallDashboardStore/addTactToAnt',
          {
            assembly_hall: this.focusedHall.id,
            mes_id: item.tactId,
            tact_start: item.startTime,
            tact_end: item.finishTime,
          }
        );
      } catch (e) {}
    },
  },
};
</script>

<template>
  <v-data-table
    :headers="[
      { text: 'MES ID', value: 'tactId' },
      { text: 'Start', value: 'startTime' },
      { text: 'End', value: 'finishTime' },
      { text: 'ANT', value: 'in_ant' },
    ]"
    :items="mesTacts"
    class="full-width"
  >
    <template #item.startTime="{ item }">
      {{ getDateTimeFromTimestamp(item.startTime) }}
    </template>
    <template #item.finishTime="{ item }">
      {{ getDateTimeFromTimestamp(item.finishTime) }}
    </template>
    <template #item.in_ant="{ item }">
      <v-simple-checkbox
        :disabled="!loggedInAnt(item)"
        :value="loggedInAnt(item)"
        @click="addTactToAnt(item)"
      ></v-simple-checkbox>
    </template>
  </v-data-table>
</template>

<style lang="scss" scoped></style>
