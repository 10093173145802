<template>
  <div
    :class="{ large: isLarge }"
    :style="{
      opacity: selectedTrestle ? (selectedTrestle === trestle ? 1 : 0.3) : 1,
    }"
    class="single-trestle-container"
  >
    <div
      class="pa-1 flex-1 d-flex flex-column"
      style="border-left: dashed 1px #6b6b6b; border-right: dashed 1px #6b6b6b"
    >
      <div
        v-for="(position, positionIndex) in trestle.positions"
        :key="positionIndex"
        class="d-flex flex-1"
      >
        <div
          v-if="position.elements.length === 0"
          class="flex-1 mb-1 d-flex align-center px-1"
          style="
            border: dotted 1px #6b6b6b;
            background: transparent;
            height: 25px;
          "
        ></div>
        <v-tooltip
          v-for="(element, elementIndex) in position.elements"
          v-else
          :key="elementIndex"
          bottom
        >
          <template #activator="{ on }">
            <div
              :class="{
                'mx-1': elementIndex < position.elements.length,
              }"
              :style="{
                border: 'solid 1px #6b6b6b',
                background: typeColorDictionary[element?.module_type],
                opacity:
                  selectedModule !== null
                    ? selectedModule === element?.module_id
                      ? 1
                      : 0.3
                    : 1,
              }"
              class="flex-1 mb-1 d-flex align-center px-1 c-pointer"
              @click="$emit('clickedElement', element)"
              v-on="on"
            >
              <div class="d-flex justify-start fs-12">
                {{ positionIndex + 1 }}.{{ elementIndex + 1 }}
              </div>
              <v-icon v-if="element?.production_id" small
                >mdi-check-circle
              </v-icon>
              <div class="flex-1 d-flex justify-end">
                {{ element.element_type ? element.element_type : '-' }}
              </div>
            </div>
          </template>
          <span>{{ element.element_id }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { is } from 'vue-wait/src/utils';

export default {
  name: 'BaseDhmeSingleTrestle',
  props: {
    trestle: {
      type: Object,
      required: true,
    },
    selectedTrestle: {
      type: Object,
      required: false,
      default: null,
    },
    typeColorDictionary: {
      type: Object,
      required: true,
    },
    selectedModule: {
      type: String,
      required: false,
      default: null,
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: { is },
};
</script>

<style lang="scss" scoped>
.single-trestle-container {
  width: 250px;
  height: 165px;
  border-bottom: solid 10px #6b6b6b;
  border-top: solid 2px #6b6b6b;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.75);
  position: relative;

  &.large {
    width: 500px;
    height: 350px;
  }
}
</style>
