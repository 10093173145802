<template>
  <div class="flex-1 d-flex overflow-x-auto">
    <div
      v-for="(option, optionIndex) in trestleOptions"
      :key="optionIndex"
      class="d-flex flex-column ant-border-right flex-1"
    >
      <div class="ant-glass-background radius-0 ant-border-bottom d-flex">
        <v-subheader>{{ option.type }}</v-subheader>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div class="d-flex mx-2 align-center" v-on="on">
              <v-icon>mdi-cube-outline</v-icon>
              {{
                option.trestles
                  .flatMap((trestle) => trestle.positions)
                  .filter((item) => !item.isEmpty).length
              }}
            </div>
          </template>
          <span>Elements</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div class="d-flex mx-2 align-center" v-on="on">
              <v-icon class="mdi-rotate-270">mdi-format-list-group</v-icon>
              {{ option.trestles.length }}
            </div>
          </template>
          <span>Trestles</span>
        </v-tooltip>
        <v-btn icon @click="exportToPdf(optionIndex)">
          <v-icon>mdi-export</v-icon>
        </v-btn>
      </div>
      <div
        :ref="`pdfContent${optionIndex}`"
        class="flex-1 mx-2 overflow-y-auto pa-4 d-flex flex-column"
      >
        <div
          v-for="(trestle, index) in option.trestles"
          :key="index"
          :class="{ 'last-item': index === option.trestles.length - 1 }"
          class="d-flex ant-border-bottom pb-2 mb-2"
        >
          <div class="d-flex align-center justify-center">
            {{ index + 1 }}
          </div>
          <div class="d-flex flex-column flex-1 align-end mr-2">
            <v-tooltip
              v-for="module in [
                ...new Set(
                  trestle.positions
                    .flatMap((p) => p.elements)
                    .map((e) => e?.module_id)
                ),
              ]"
              :key="module"
              left
            >
              <template #activator="{ on }">
                <v-chip
                  :color="getModuleColor(module, trestle)"
                  :style="{
                    opacity:
                      selectedModule !== null
                        ? selectedModule === module
                          ? 1
                          : 0.3
                        : 1,
                  }"
                  class="mb-1"
                  small
                  @click="
                    selectedModule === module
                      ? (selectedModule = null)
                      : (selectedModule = module)
                  "
                  v-on="on"
                  >{{ module }}
                </v-chip>
              </template>
              <span>{{ getTypeByModuleId(module, trestle) }}</span>
            </v-tooltip>
          </div>
          <base-dhme-single-trestle
            :selected-module="selectedModule"
            :trestle="trestle"
            :type-color-dictionary="typeColorDictionary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { executeCustomModuleCall } from '@/services/api/module.api';
import { mapGetters } from 'vuex';
import {
  createModuleTypeColorDictionary,
  getModuleColor,
  getModuleTypeByElements,
} from '@/modules/daiwa-house-modular-europe/AssemblyHallDashboard/assembly-hall-trestles.utils';
import BaseDhmeSingleTrestle from '@/components/Modules/Daiwa-House-Modular-Europe/Base/BaseDhmeSingleTrestle.vue';
import { generateAutomatedVirtualTrestlesByPosition } from '@/services/dhme/trestles-helper';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
  name: 'AutomatedTrestleOverview',
  components: { BaseDhmeSingleTrestle },
  props: {
    selectedProject: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      selectedModule: null,
      trestleOptions: [],
      trestles: [],
      typeColorDictionary: {},
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      moduleId: 'dhmeAssemblyHallDashboardStore/moduleId',
    }),
  },
  watch: {
    selectedProject: {
      immediate: true,
      async handler(value) {
        if (value) {
          this.$emit('changeLoadingState', true);
          this.modules = await executeCustomModuleCall(
            this.project.id,
            this.moduleId,
            'fetchModulesForProject',
            {
              project: value.id,
            }
          );
          this.createTypeColorDictionary();
          this.createVirtualTrestles();
        }
      },
    },
  },
  methods: {
    async exportToPdf(index2) {
      const content = this.$refs[`pdfContent${index2}`][0];
      const currentScroll = content.scrollTop;
      const pdf = new jsPDF('p', 'mm', 'a4');
      const contentHeight = content.scrollHeight;
      const pageHeight = 1122; // height of A4 page in px
      const scale = 2; // Increase the scale for better quality
      let currentPage = 0;

      content.scrollTop = 0;
      while (currentPage * pageHeight < contentHeight) {
        const canvas = await html2canvas(content, {
          scrollY: -window.scrollY - (currentPage * pageHeight) / scale,
          scale: scale,
        });
        const imgData = canvas.toDataURL('image/png');
        if (currentPage === 0) {
          pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // first page
        } else {
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // subsequent pages
        }
        currentPage++;
        if (content.scrollTop + content.offsetHeight < content.scrollHeight) {
          content.scrollTop = content.scrollTop + content.offsetHeight;
        } else {
          content.scrollTop = content.offsetHeight;
        }
      }

      content.scrollTop = currentScroll;

      pdf.save('content.pdf');
    },
    getModuleColor(moduleId, trestle) {
      return getModuleColor(moduleId, trestle, this.typeColorDictionary);
    },
    getTypeByModuleId(moduleId, trestle) {
      return getModuleTypeByElements(moduleId, trestle);
    },
    createTypeColorDictionary() {
      this.typeColorDictionary = createModuleTypeColorDictionary(this.modules);
    },
    createVirtualTrestles() {
      this.trestleOptions = [
        {
          type: 'Header walls (north)',
          position: ['1'],
          trestles: [],
        },
        {
          type: 'Header walls (south)',
          position: ['2'],
          trestles: [],
        },
        {
          type: 'Side walls (west)',
          position: ['3'],
          trestles: [],
        },
        {
          type: 'Side walls (east)',
          position: ['4'],
          trestles: [],
        },
        {
          type: 'Inside walls',
          position: ['5'],
          trestles: [],
        },
        {
          type: 'Exterior walls',
          position: ['8'],
          trestles: [],
        },
        {
          type: 'Roofs',
          position: ['6'],
          trestles: [],
        },
      ];

      this.trestleOptions.forEach((option) => {
        option.trestles = generateAutomatedVirtualTrestlesByPosition(
          this.modules,
          option.position
        );
      });

      this.$emit('changeLoadingState', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.last-item {
  margin-bottom: 100vh !important;
}
</style>
