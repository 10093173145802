<template>
  <div class="flex-1 d-flex flex-column ant-border-left" style="width: 0">
    <div
      class="d-flex flex-column ant-glass-background radius-0 ant-border-bottom"
    >
      <div class="px-5 py-2 d-flex">
        <ant-input is-optional label="Project" top-margin="mt-0">
          <template #input-field>
            <v-select
              v-model="selectedProject"
              :items="projects"
              dense
              filled
              hide-details
              item-text="name"
              placeholder="Project"
              return-object
            >
              <template #item="{ item }">
                <div class="d-flex align-center justify-center">
                  <span>{{ item.name }}</span>
                  <v-spacer />
                  <span class="ml-5 font-italic fs-10">{{
                    item.start_date
                  }}</span>
                </div>
              </template>
            </v-select>
          </template>
        </ant-input>

        <v-spacer />
        <div v-if="selectedProject && modules.length > 0" class="d-flex">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div class="d-flex mx-2 align-center" v-on="on">
                <v-icon>mdi-cube</v-icon>
                {{ moduleCount }}
              </div>
            </template>
            <span>Modules</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div class="d-flex mx-2 align-center" v-on="on">
                <v-icon>mdi-cube-outline</v-icon>
                {{ elementCount }}
              </div>
            </template>
            <span>Elements</span>
          </v-tooltip>
        </div>
      </div>

      <v-progress-linear
        :style="{ opacity: isLoading ? 1 : 0 }"
        color="primary"
        indeterminate
      />
    </div>

    <div v-if="selectedProject" class="d-flex flex-grow-1 overflow-y-auto">
      <automated-trestle-overview
        v-if="focusedHall.automated"
        :module-id="moduleId"
        :selected-project="selectedProject"
        @changeLoadingState="updateLoadingState"
      />
      <manual-trestle-overview
        v-else-if="!focusedHall.automated"
        :module-id="moduleId"
        :selected-project="selectedProject"
        @changeLoadingState="updateLoadingState"
      />
    </div>
  </div>
</template>

<script>
import { executeCustomModuleCall } from '@/services/api/module.api';
import { mapGetters } from 'vuex';
import { DHME_M_ASSEMBLY_HALL_DASHBOARD } from '@/modules/modules';
import AutomatedTrestleOverview from '@/modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboardAutomatedTrestles.vue';
import ManualTrestleOverview from '@/modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboardManualTrestles.vue';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'TrestlesOverview',
  components: { AntInput, ManualTrestleOverview, AutomatedTrestleOverview },
  data: () => {
    return {
      selectedDateMenu: false,
      selectedDate: null,
      isLoading: false,
      modules: [],
      projects: [],
      selectedProject: null,
      selectedModule: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      focusedHall: 'dhmeAssemblyHallDashboardStore/focusedHall',
    }),

    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_ASSEMBLY_HALL_DASHBOARD
      ).id;
    },

    moduleCount() {
      return this.modules.length;
    },

    elementCount() {
      return this.modules.flatMap((module) => module.elements).length;
    },
  },
  watch: {
    focusedHall: {
      immediate: true,
      async handler(value) {
        if (value) {
          this.isLoading = true;
          this.projects = await executeCustomModuleCall(
            this.project.id,
            this.moduleId,
            'fetchHallProjects',
            {
              hall: this.focusedHall.id,
            }
          );
          this.selectedProject = null;
          this.isLoading = false;
        }
      },
    },
  },
  methods: {
    updateLoadingState(bool) {
      this.isLoading = bool;
    },
  },
};
</script>

<style lang="scss" scoped></style>
