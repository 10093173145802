<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DhmeStationDashboardAssemblyTaskCard',
  props: {
    task: {
      type: Object,
      required: true,
    },
    details: {
      type: Boolean,
      default: false,
    },
    focusedTask: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },
  },
  computed: {
    ...mapGetters({
      tacts: 'dhmeAssemblyHallDashboardStore/tacts',
      locations: 'dhmeAssemblyHallDashboardStore/hallLocations',
    }),
    iconColor() {
      if (this.task.status === 'canceled') {
        return 'warning';
      } else if (this.task.task_type.custom_7 === 'inactive') {
        return 'error';
      }
      return '';
    },
    taskIcon() {
      if (this.task.status === 'canceled') {
        return 'mdi-cancel';
      } else if (this.task.task_type.type === 'dhme-module-assembly') {
        return 'mdi-cube';
      } else if (this.task.task_type.type === 'dhme-element-assembly') {
        return 'mdi-cube-outline';
      }
      return 'mdi-plus';
    },
    mesId() {
      return this.tacts.find((x) => x.id === this.task.task_type.custom_9)
        ?.mes_id;
    },
  },
};
</script>

<template>
  <v-card
    :color="focusedTask?.id === task.id ? 'primary' : ''"
    :dark="focusedTask?.id === task.id"
    class="mb-2"
    outlined
    @click="$emit('click', task)"
  >
    <div
      v-if="
        ['dhme-module-assembly', 'dhme-element-assembly'].includes(
          task.task_type.type
        )
      "
      class="d-flex flex-column"
    >
      <div class="d-flex align-center flex-1 pa-4">
        <v-icon :color="iconColor" class="mr-2">
          {{ taskIcon }}
        </v-icon>
        <div class="mr-2 d-flex flex-column">
          <span>{{ task.assemblyItem.code ?? '-' }}</span>
          <span class="font-italic fs-10">{{ task.assemblyItem.id }}</span>
        </div>
        <v-spacer />
        <span>{{ task.assemblyItem.type }}</span>
      </div>
      <div v-if="details" class="px-2 pb-1 d-flex">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon dense v-bind="attrs" v-on="on">
              {{
                task.task_type.custom_3
                  ? 'mdi-calendar-check'
                  : 'mdi-calendar-question'
              }}
            </v-icon>
          </template>
          <span>{{
            task.task_type.custom_3
              ? `Planned on location '${
                  locations.find((t) => t.id === task.task_type.custom_11)
                    ?.assembly_location
                }'`
              : 'Location unknown'
          }}</span>
        </v-tooltip>
        <v-tooltip v-if="task.status === 'closed'" bottom>
          <template #activator="{ on, attrs }">
            <v-icon class="mx-2" dense v-bind="attrs" v-on="on">
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </template>
          <span>Fully completed</span>
        </v-tooltip>
        <v-tooltip v-if="task.task_type.custom_7 === 'inactive'">
          <template #activator="{ on, attrs }">
            <v-icon class="mx-2" color="error" dense v-bind="attrs" v-on="on"
              >mdi-signal-off
            </v-icon>
          </template>
          <span>AGV is offline</span>
        </v-tooltip>
        <v-tooltip v-if="task.task_type.custom_9">
          <template #activator="{ on, attrs }">
            <div class="mx-2" v-bind="attrs" v-on="on">
              <v-icon class="mr-1" dense>mdi-clock-start</v-icon>
              <span class="fs-10">{{ mesId }}</span>
            </div>
          </template>
          <span>Tact nr.</span>
        </v-tooltip>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-chip label outlined small v-bind="attrs" v-on="on"
              >{{ task.task_project.number }}
            </v-chip>
          </template>
          <span>{{ task.task_project.name }}</span>
        </v-tooltip>
      </div>
    </div>
    <v-card-text
      v-else-if="task.task_type.type === 'dhme-assembly-space'"
      class="d-flex"
    >
      <v-icon class="mr-2">mdi-alert</v-icon>
      <span>Empty space</span>
      <v-spacer />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-chip label outlined small v-bind="attrs" v-on="on"
            >{{ task.task_project.number }}
          </v-chip>
        </template>
        <span>{{ task.task_project.name }}</span>
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped></style>
